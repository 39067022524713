import React from 'react'
import axios from 'axios'
import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'
import { Input, FormSection } from 'components'

const LayoutWrapper = styled.div`
	max-width: 600px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
`

const CompanyItem = styled.div`
	padding: 10px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
	background: #fff;
	cursor: pointer;
	outline: 2px solid transparent;
	margin: 1px;
	:hover {
		background: #fafafa;
	}
	&.active {
		outline: 2px solid ${props => props.theme.primaryColor};
		background: #fafafa;
	}
`

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	@media (max-width: 480px) {
		display: flex;
		flex-wrap: wrap;
	}
`

const Field = styled.div`
	min-width: 200px;
	margin-right: 10px;
	flex-grow: 1;
	grid-column: ${props => (props.wide ? '1 / span 2' : '')};
`

const CompanySearch = () => {
	const history = useHistory()
	const [data, setData] = React.useState([])
	const [query, setQuery] = React.useState('')
	const debouncedQuery = useDebounce(query, 200)

	const fetchData = q => {
		if (!q && q !== '') {
			return
		}
		axios.get(`/api/companies/autocomplete?q=${q}`).then(res => {
			setData(res.data)
		})
	}

	React.useEffect(() => fetchData(debouncedQuery), [debouncedQuery])

	return (
		<LayoutWrapper>
			<FormSection>
		<h1 style={{marginBottom: 0}}>Aloita hakemuksen tekeminen</h1>
		<p style={{marginTop: 0}}>Tarkista yrityksen tiedot ja korjaa tarvittaessa</p>
				<div>
					<Wrapper>
						<Field wide>
							<Input
								value={query}
								onChange={e => {
									setQuery(e.target.value)
								}}
								type="text"
								label={'Syötä yrityksen nimi tai y-tunnus'}
								placeholder="Hae"
							/>
							<div>
								{data.map(item => {
									return (
										<CompanyItem
											onClick={() => {
												history.push({
													pathname: '/form/new',
													state: {
														companyId: item.id,
													},
												})
											}}
										>
											{item.name} ({item.id})
										</CompanyItem>
									)
								})}
							</div>
						</Field>
					</Wrapper>
				</div>
			</FormSection>
		</LayoutWrapper>
	)
}

function useDebounce(value, delay) {
	const [debouncedValue, setDebouncedValue] = React.useState(value)

	React.useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value)
		}, delay)

		return () => {
			clearTimeout(handler)
		}
	}, [value, delay])

	return debouncedValue
}

export default CompanySearch
