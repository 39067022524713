import React from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import Color from 'color'

const DropZoneWrapper = styled.div`
	color: ${props => props.theme.primaryColor};
	padding: 10px 0px;
	border: 2px dashed;
	border-radius: 5px;
	outline: none;
	cursor: pointer;
	transition: color 0.2s ease;
	&:hover {
		color: ${props =>
			Color(props.theme.primaryColor)
				.darken(0.3)
				.string()};
	}
`

const InputLabel = styled.div`
	padding: 0px 10px;
	font-size: 1em;
	color: inherit;
	transition: transform 0.2s ease;
`

const FilesWrapper = styled.div`
	padding: 10px 10px;
`

const FileInput = styled.input`
	outline: none;
	border: none;
`

const NoFiles = ({ isDragActive }) => {
	if (isDragActive) {
		return (
			<div style={{ textAlign: 'center' }}>
				<p>Pudota tiedostot tähän</p>
			</div>
		)
	}
	return (
		<div style={{ textAlign: 'center' }}>
			<p>Lisää tiedostoja klikkaamalla tai raahaamalla ne tähän</p>
		</div>
	)
}

const DropZone = ({ label }) => {
	const [files, setFiles] = React.useState([
		{ name: 'file1' },
		{ name: 'file2' },
	])

	const onDrop = React.useCallback(acceptedFiles => {
		setFiles(files => [...files, ...acceptedFiles])
	}, [])

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	})

	return (
		<DropZoneWrapper {...getRootProps()}>
			<FileInput {...getInputProps()} />
			<InputLabel>{label}</InputLabel>
			<FilesWrapper>
				<Files files={files} />
				<NoFiles isDragActive={isDragActive} />
			</FilesWrapper>
		</DropZoneWrapper>
	)
}

const Files = ({ files }) => {
	console.log(files)
	return files.map((f, k) => <div key={k}>{f.name}</div>)
}

export default DropZone
