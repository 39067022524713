import React from 'react'
import styled from 'styled-components/macro'
import { Button } from 'components'

const Wrapper = styled.div`
	max-width: 600px;
	margin: 0 auto;
	padding: 0 30px;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;

	h1,
	p {
		margin: 0px;
		margin-bottom: 1rem;
	}
`

const Success = ({ match }) => {
	const application = match.params.applicationId
	return (
		<Wrapper>
			<h1>Kiitos!</h1>
			<p>Yrityssaneeraushakemus on nyt luotu.</p>
			<p>
				Alla olevat asiakirjat on lähetetty allekirjoitettavaksi tarvittaville
				asianosaisille.
			</p>
			<p>
				Allekirjoitusten jälkeen kyseiset asiakirjat toimitetaan yrityksesi
				kotipaikan mukaan määräytyvän käräjäoikeuden käsiteltäväksi.
			</p>
			<p> Käy katsomassa sähköpostisi jatko-ohjeiden osalta.</p>
			<p>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={'/api/applications/' + application + '/invoice'}
				>
					Lataa kuitti
				</a>
			</p>
			<h1>Luodut asiakirjat:</h1>
			<a
				target="_blank"
				rel="noopener noreferrer"
				href={'/api/applications/' + application + '/boardnotes'}
			>
				Päätöspöytäkirja
			</a>
			<a
				target="_blank"
				rel="noopener noreferrer"
				href={'/api/applications/' + application + '/application'}
			>
				Hakemus
			</a>
			<p>&nbsp;</p>
			<Button as="a" href="/" variant="filled">
				Takaisin alkuun
			</Button>
		</Wrapper>
	)
}

export default Success
