import React from 'react'
import axios from 'axios'
import styled from 'styled-components/macro'
import { Redirect } from 'react-router-dom'
import { FormSection, PageTransition, Timeline } from 'components'
import pages from './pages'

const Wrapper = styled.div`
	max-width: 600px;
	margin: 0 auto;
	padding: 0 30px;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	@media(max-width: 500px) {
		padding: 0px 10px;
	}
`

const RenderPage = ({ data, ...rest }) => (
	<FormSection>
		<h1>{data.title}</h1>
		<div>{data.Component && <data.Component {...rest} />}</div>
	</FormSection>
)

function App({ match, history }) {
	const applicationId = match.params.applicationId
	const [application, setApplication] = React.useState({ company: {} })
	const [, setFinnished] = React.useState(false)
	const [page, setPage] = React.useState(0)
	const [direction, setDirection] = React.useState(1)

	const totalPages = pages.length

	const handleMoveTo = p => {
		setDirection(p > page ? 1 : -1)
		setPage(p)
	}

	const handleSubmit = () => {
		setFinnished(true)
	}

	const handleNext = () => {
		setDirection(1)
		if (page >= totalPages - 1) {
			handleSubmit()
			return
		}
		setPage(page => page + 1)
	}
	const handlePrev = () => {
		setDirection(-1)
		if (page > 0) {
			setPage(page - 1)
			return
		}

		history.replace('/')
	}

	const fetchData = c => {
		console.log(c)
		axios.get(`/api/applications/${c}`).then(res => {
			setApplication(res.data)
			if (res.data.status === 'AUTHORIZER_ADDED') {
				setPage(1)
			}
			if (res.data.status === 'STAKEHOLDERS_FILLED') {
				setPage(2)
			}
			if (res.data.status === 'PAYMENT_REQUIRED') {
				setPage(3)
			}
		})
	}

	React.useEffect(() => fetchData(applicationId), [applicationId])

	if (
		application.status === 'PAID' ||
		application.status === 'SIGNATURE_PENDING' ||
		application.status === 'FINISHED'
	) {
		return <Redirect to={`/form/${applicationId}/success`} />
	}

	return (
		<Wrapper>
			<Timeline
				page={page}
				pages={pages}
				totalPages={totalPages}
				moveTo={handleMoveTo}
			/>
			<div>
				<PageTransition page={page} direction={direction}>
					<RenderPage
						key={page}
						data={pages[page]}
						onNext={handleNext}
						application={application}
						setApplication={setApplication}
						page={page}
						totalPages={totalPages}
						onPrev={handlePrev}
					/>
				</PageTransition>
			</div>
		</Wrapper>
	)
}

export default App
