import React from 'react'
import axios from 'axios'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { Input, Button, FormNav } from 'components'
import { isValidEmail } from '../../lib/validation';

const Wrapper = styled.div`
	@media (max-width: 480px) {
		display: flex;
		flex-wrap: wrap;
	}
`

const Field = styled.div`
	min-width: 200px;
	margin-right: 10px;
	flex-grow: 1;
	grid-column: ${props => (props.wide ? '1 / span 2' : '')};
`

const FieldSection = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	@media (max-width: 480px) {
		display: flex;
		flex-wrap: wrap;
	}
	padding: 10px 0px 0px 10px;
	border: 1px solid ${props => props.theme.primaryColor};
	border-radius: 5px;
	margin-bottom: 10px;
`

const Label = styled.div`
	color: ${props => props.theme.primaryColor};
	grid-column: 1/-1;
	margin-bottom: 10px;
	font-size: 11pt;
	font-weight: bold;
`

const OwnerInfo = ({
	onNext,
	page,
	totalPages,
	onPrev,
	loading,
	application,
	setApplication,
}) => {
	const [fields, setFields] = React.useState([{ name: '', email: '' }])
	const match = useRouteMatch('/form/:applicationId')

	const updateField = (i, field, value) => {
		setFields(status => {
			status[i][field] = value
			return [...status]
		})
	}

	const updateOwners = () => {
		axios
			.put('/api/applications/' + match.params.applicationId, {
				stakeHolders: fields
					.filter(f => f.name !== '' && f.email !== '')
					.map(f => ({
						...f,
						companyId: application.id,
					})),
			})
			.then(res => setApplication(res.data))
			.then(onNext)
	}

	const isValidOwners = () => {
		if (fields.length < 1) {
			return false;
		}
		return fields.every(owner => {
			return isValidEmail(owner.email) && !!owner.name;
		});
	}

	const disableAdd = fields.length >= 100

	const addOwner = () => {
		if (!disableAdd) {
			setFields(f => [...f, { name: '', email: '' }])
		}
	}

	React.useEffect(() => {
		if (application.stakeHolders.length > 0) {
			setFields(application.stakeHolders)
		}
	}, [application.stakeHolders])

	return (
		<Wrapper>
			{fields.map((field, i) => {
				console.log(field)
				return (
					<FieldSection>
						<Label>Omistaja {i + 1}</Label>
						<Field>
							<Input
								name="name"
								value={fields[i].name}
								onChange={e => updateField(i, 'name', e.target.value)}
								type="text"
								label={'Koko nimi'}
							/>
						</Field>
						<Field>
							<Input
								value={fields[i].email}
								type="email"
								label={'Sähköposti'}
								onChange={e => updateField(i, 'email', e.target.value)}
							/>
						</Field>
					</FieldSection>
				)
			})}
			<Field wide style={{ textAlign: 'center' }}>
				<Button variant="filled" onClick={addOwner} disabled={disableAdd}>
					Lisää uusi omistaja
				</Button>
			</Field>
			<FormNav
				page={page}
				totalPages={totalPages}
				onNext={updateOwners}
				onPrev={onPrev}
				loading={loading}
				disabled={!isValidOwners()}
			/>
		</Wrapper>
	)
}

export default OwnerInfo
