import React from 'react'
import styled from 'styled-components/macro'
import { Button } from 'components'

const NavWrapper = styled.div`
	z-index: 200;
	display: flex;
	justify-content: space-between;
	padding: 20px 0px;
	width: 100%;
	grid-column: 1/-1;
	padding-right: 10px;
	box-sizing: border-box;
`

const FormNav = ({ loading, page, totalPages, onNext, onPrev, disabled }) => {
	const FinishButtonText = () => {
		if (loading) {
			return 'Tallennetaan...'
		}
		if (page === totalPages - 1) {
			return 'Valmis'
		}
		return 'Seuraava'
	}
	return (
		<NavWrapper>
			<Button onClick={onPrev}>Edellinen</Button>
			<Button disabled={loading || disabled} onClick={onNext} variant="filled">
				<FinishButtonText />
			</Button>
		</NavWrapper>
	)
}

export default FormNav
