import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import styled from 'styled-components/macro'
import { Button } from 'components'

const Wrapper = styled.div`
	max-width: 600px;
	margin: 0 auto;
	padding: 0 30px;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	h1,
	p {
		margin: 0px;
		margin-bottom: 1rem;
	}
`

const all = (...targets) => {
	for (let i = 0; i < targets.length; i++) {
		if (targets[i] !== true) {
			return false
		}
	}
	return true
}

const formatPrice = value => {
	return (value / 100).toFixed(2) + ' €'
}

const formatPercentage = value => {
	return value / 100 + ' %'
}

const SummaryTable = styled.table`
	border-collapse: collapse;
	font-size: 1rem;
	margin: 10px 0px;

	tr.separator {
		td {
			border-bottom: 2px solid #222;
			padding-top: 0.25rem;
		}
	}

	tr.sum,
	tr.sum td {
		font-size: 1.5rem;
		font-weight: bold;
		padding: 0.25rem 0px;
	}

	td.val {
		text-align: right;
	}
`

const Summary = ({ amount = 0, total = 0, vat = 0, vatPercentage = 0 }) => {
	return (
		<SummaryTable>
			<tbody>
				<tr>
					<td>Yrityssaneeraushakemus</td>
					<td className="val">{formatPrice(amount)}</td>
				</tr>
				<tr>
					<td>ALV({formatPercentage(vatPercentage)})</td>
					<td className="val">{formatPrice(vat)}</td>
				</tr>
				<tr className="separator">
					<td></td>
					<td></td>
				</tr>
				<tr className="sum">
					<td>Yhteensä</td>
					<td className="val">{formatPrice(total)}</td>
				</tr>
			</tbody>
		</SummaryTable>
	)
}

const Claims = ({ value, onChange }) => {
	const initValue = { terms: false, privacy: false }
	const [v, setV] = useState(initValue)

	const handleChange = e => {
		setV(val => {
			val[e.target.name] = e.target.checked
			console.log(val, e.target.name, e.target.checked)
			return { ...val }
		})
	}

	useEffect(() => {
		if (all(v.terms, v.privacy)) {
			onChange(true)
			return
		}
		onChange(false)
	}, [v])

	useEffect(() => {
		if (value === true) {
			setV({ terms: true, privacy: true })
			return
		}
		setV(initValue)
	}, [value])

	return (
		<div style={{ marginBottom: 20 }}>
			<div>
				<input type="checkbox" name="terms" onChange={handleChange} /> Hyväksyn
				palvelun{' '}
				<a
					href="https://yrityskorjaamo.fi/yleiset-palveluehdot"
					target="_blank"
				>
					käyttöehdot
				</a>
			</div>
			<div>
				<input type="checkbox" name="privacy" onChange={handleChange} />{' '}
				Hyväksyn palvelun{' '}
				<a href="https://yrityskorjaamo.fi/tietosuojaseloste" target="_blank">
					tietosuojaselosteen
				</a>
			</div>
		</div>
	)
}

const LinkButton = ({ disabled, children, ...props }) => {
	return (
		<Button as="a" variant="filled" disabled={disabled} {...props}>
			{children}
		</Button>
	)
}

const Payment = ({ application }) => {
	const [data, setData] = useState({})
	const [status, setStatus] = useState('loading')
	const [error, setError] = useState(null)
	const [claims, setClaims] = useState(false)

	const fetchPayment = () => {
		axios
			.get('/api/payments/' + application.id)
			.then(res => {
				console.log('Payment', res.data)
				setData(res.data)
				setStatus('success')
				setError(null)
			})
			.catch(err => {
				setStatus('error')
				setError(err)
			})
	}

	useEffect(fetchPayment, [application.id])

	if (status === 'loading') {
		return 'Ladataan...'
	}

	if (status === 'error') {
		console.log(error)
		return 'Virhe maksun luomisessa'
	}

	return (
		<Wrapper>
			<h1>Kaikki tarvittavat tiedot on nyt täytetty</h1>
			<p>
				Jatkaaksesi, siirry maksamaan saneeraushakemus. Tämän jälkeen luotu
				osakkeenomistajien päätöspöytäkirja sekä yrityssaneeraushakemus lähetetään
				asianosaisille allekirjoitettavaksi.
			</p>
			<p>
				Kun dokumentit on allekirjoitettu kaikkien asianosaisten toimesta,
				lähetetään valmis hakemus yrityksen kotipaikan mukaan määräytyvän
				käräjäoikeuden käsiteltäväksi.
			</p>
			<p>
				Tämän lisäksi pääset tarkastelemaan ja lataamaan järjestelmän luomat
				dokumentit itsellesi heti maksun jälkeen.
			</p>
			<Summary
				amount={data.amount}
				total={data.total}
				vat={data.vat}
				vatPercentage={data.vatPercentage}
			/>
			<Claims value={claims} onChange={v => setClaims(v)} />
			<LinkButton href={data.paymentLink} disabled={!claims}>
				Siirry maksamaan
			</LinkButton>
		</Wrapper>
	)
}

export default Payment
