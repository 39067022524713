import CompanyInfo from 'views/CompanyInfo'
import Authorizer from 'views/Authorizer'
import OwnerInfo from 'views/OwnerInfo'
import Payment from 'views/Payment'

const pages = [
	{ title: 'Yrityksen tiedot', Component: CompanyInfo },
	{ title: 'Yhteystiedot', Component: Authorizer },
	{ title: 'Osakkeenomistajien tiedot', Component: OwnerInfo },
	{ title: '', Component: Payment },
]

export default pages
