import React from 'react'
import styled from 'styled-components/macro'
import Color from 'color'

const ButtonStyle = styled.button`
	padding: 10px 20px;
	font-size: 1em;
	cursor: pointer;
	border: none;
	border-radius: 5px;
	outline: none;
	background: none;
	text-decoration: none;
	text-align: center;
	&:hover {
		background: #eee;
	}

	&.filled {
		color: #fff;
		background: ${props => props.theme.accentColor};
		&:hover {
			background: ${props =>
				Color(props.theme.accentColor).darken(0.2).string()};
		}
	}

	&.disabled {
		pointer-events: none;
		color: #f8f8f8;
		background: #ccc;
		&:hover {
			background: #ccc;
		}
	}
`

const Button = ({ onClick, variant, disabled, ...props }) => {
	const handleClick = e => {
		if (typeof onClick === 'function') {
			e.preventDefault()
			e.stopPropagation()
			onClick(e)
		}
	}
	return (
		<ButtonStyle
			className={disabled ? 'disabled' : variant}
			onClick={handleClick}
			disabled={disabled}
			{...props}
		/>
	)
}

export default Button
