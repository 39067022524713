import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { Input, FormNav } from 'components'
import { useRouteMatch } from 'react-router-dom'

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	@media (max-width: 480px) {
		display: flex;
		flex-wrap: wrap;
	}
`

const Field = styled.div`
	min-width: 200px;
	margin-right: 10px;
	flex-grow: 1;
	grid-column: ${props => (props.wide ? '1 / span 2' : '')};
`

const CompanyInfo = ({
	onNext,
	application,
	setApplication,
	query,
	setQuery,
	page,
	totalPages,
	onPrev,
	loading,
}) => {
	const match = useRouteMatch('/form/:applicationId')
	const [cd, setCd] = useState(application?.company || {})

	const updateCompany = () => {
		axios
			.put('/api/applications/' + match.params.applicationId, {
				company: cd,
			})
			.then(onNext)
	}

	useEffect(() => {
		if (application?.company) {
			setCd(application.company)
		}
	}, [application])

	return (
		<Wrapper>
			<Field wide>
				<Input
					value={cd.id}
					type="text"
					label={'Y-tunnus'}
					placeholder="Esim. Helsinki"
				/>
			</Field>
			<Field wide>
				<Input
					value={cd.name}
					type="text"
					label={'Toiminimi'}
					placeholder="Esim. Helsinki"
				/>
			</Field>
			<Field wide>
				<Input
					value={cd.hometown}
					type="text"
					label={'Kotipaikkakunta'}
					placeholder="Esim. Helsinki"
				/>
			</Field>
			<Field wide>
				<Input
					value={cd.address}
					type="text"
					label={'Katuosoite'}
					onChange={e => setCd(c => ({ ...c, address: e.target.value }))}
					placeholder="Esim. PL 881"
				/>
			</Field>
			<Field wide>
				<Input
					value={cd.city}
					type="text"
					label={'Kaupunki'}
					onChange={e => setCd(c => ({ ...c, city: e.target.value }))}
					placeholder="Esim. Helsinki"
				/>
			</Field>
			<Field wide>
				<Input
					value={cd.zipCode}
					type="text"
					label={'Postinumero'}
					onChange={e => setCd(c => ({ ...c, zipCode: e.target.value }))}
					placeholder="Esim. 00101"
				/>
			</Field>
			<FormNav
				page={page}
				totalPages={totalPages}
				onNext={updateCompany}
				onPrev={onPrev}
				loading={loading}
			/>
		</Wrapper>
	)
}

export default CompanyInfo
