import React from 'react'
import styled from 'styled-components/macro'

const SectionWrapper = styled.div`
	border-radius: 10px;
	display: flex;
	flex-direction: column;
`

const FormSection = ({ children }) => (
	<SectionWrapper>{children}</SectionWrapper>
)

export default FormSection
