import React from 'react'
import axios from 'axios'
import styled, {
	ThemeProvider,
	createGlobalStyle,
} from 'styled-components/macro'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom'
import Form from 'views/Form'
import CompanySearch from 'views/CompanySearch'
import Success from 'views/Success'
import defaultTheme from 'defaultTheme'

const GlobalStyle = createGlobalStyle`
	html, body {
		margin: 0;
		font-family: 'Poppins', sans-serif;
		background: #eaedee;
	}
	h1 {
		font-size: 1em;
		color: ${props => props.theme.primaryColor};
	}
`

const FormContainer = styled.div`
	background: #fff;
	padding: 20px;
	max-width: 600px;
	margin: 40px auto;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	@media(max-width: 500px) {
		margin: 20px auto;
	}
`

const NewForm = ({ location }) => {
	const [status, setStatus] = React.useState('loading')
	const [error, setError] = React.useState(null)
	const [id, setId] = React.useState('')
	const companyId = location?.state?.companyId || ''

	const createForm = () => {
		const stored = localStorage.getItem('applicationInfo')
		if (stored && stored.appId && stored.companyId === companyId) {
			setId(stored)
			setStatus('success')
			return
		}
		if (companyId === '') {
			return
		}

		axios
			.post(`/api/applications`, { companyId })
			.then(res => {
				setId(res.data.id)
				setStatus('success')
				localStorage.setItem('applicationInfo', {
					appId: res.data.id,
					companyId,
				})
			})
			.catch(err => {
				setError(err)
			})
	}

	React.useEffect(createForm, [companyId])

	if (status === 'success' && id !== '') {
		return <Redirect to={`/form/${id}`} />
	}

	if (status === 'loading') {
		return <div>Loading</div>
	}

	return <div>Error: {error}</div>
}

function App() {
	return (
		<ThemeProvider theme={defaultTheme}>
			<GlobalStyle />
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					padding: 20,
					background: '#fff',
					boxShadow: '0px 2px 10px rgba(0,0,0,0.1)',
				}}
			>
				<div style={{ maxWidth: 1200, textAlign: 'left', width: '100%' }}>
					<a href="https://yrityskorjaamo.fi">
						<img
							style={{ width: 200, marginBottom: -10 }}
							alt="yrityskorjaamo"
							src="/yrityskorjaamo_logo.jpg"
						/>
					</a>
				</div>
			</div>
			<FormContainer>
				<h1 style={{ textAlign: 'center' }}>Yrityssaneeraus&shy;hakemus</h1>
				<Router>
					<Switch>
						<Route path="/" exact component={CompanySearch} />
						<Route path="/form/new" component={NewForm} />
						<Route path="/form/:applicationId/success" component={Success} />
						<Route path="/form/:applicationId" component={Form} />
					</Switch>
				</Router>
			</FormContainer>
		</ThemeProvider>
	)
}

export default App
