import React, { useCallback } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { useRouteMatch } from 'react-router-dom'
import { Input, FormNav } from 'components'
import { isValidPhone, isValidEmail } from 'lib/validation'

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	@media (max-width: 480px) {
		display: flex;
		flex-wrap: wrap;
	}
`

const Field = styled.div`
	min-width: 200px;
	margin-right: 10px;
	flex-grow: 1;
	grid-column: ${props => (props.wide ? '1 / span 2' : '')};
`

const useField = name => {
	const [value, setValue] = React.useState('')
	const [error, setError] = React.useState(null)

	return {
		name,
		value,
		setValue,
		onChange: e => {
			setError(null)
			setValue(e.target.value)
		},
		error,
		setError,
	}
}

const Authorizer = ({ onNext, page, totalPages, onPrev, loading }) => {
	const match = useRouteMatch('/form/:applicationId')
	const [contactId, setContactId] = React.useState('')
	const name = useField('name')
	const phone = useField('phone')
	const email = useField('email')

	const fetchData = useCallback(c => {
		if (!c || c === '') {
			return
		}
		axios.get(`/api/applications/${c}`).then(res => {
			console.log(res.data)
			setContactId(res.data.authorizer.id)
			name.setValue(res.data.authorizer.name)
			email.setValue(res.data.authorizer.email)
			phone.setValue(res.data.authorizer.phone)
		})
		// eslint-disable-next-line
	}, [])

	React.useEffect(
		() => fetchData(match.params.applicationId),
		[fetchData, match.params.applicationId],
	)

	const updateAuthorizer = () => {
		let valid = true

		if (name.value === '') {
			name.setError('Vaadittu kenttä')
			valid = false
		}
		if (email.value === '') {
			email.setError('Vaadittu kenttä')
			valid = false
		}
		if (phone.value === '') {
			phone.setError('Vaadittu kenttä')
			valid = false
		}

		if (!isValidPhone(phone.value)) {
			phone.setError('Virheellinen puhelinnumero')
			valid = false
		}

		if (!isValidEmail(email.value)) {
			email.setError('Virheellinen sähköposti')
			valid = false
		}

		if (!valid) {
			return
		}

		axios
			.put('/api/applications/' + match.params.applicationId, {
				authorizer: {
					id: contactId,
					name: name.value,
					phone: phone.value,
					email: email.value,
				},
			})
			.then(onNext)
	}

	return (
		<Wrapper>
			<Field wide>
				<p>
					Syötä omat yhteystietosi ja klikkaa "seuraava", jolloin
					lähetämme ilmoittamaasi sähköpostiosoitteeseen viestin sisältäen
					vahvistuslinkin.
				</p>
			</Field>
			<Field wide>
				<Input
					type="text"
					label={'Koko nimi'}
					placeholder="Esim. Matti Meikäläinen"
					{...name}
				/>
			</Field>
			<Field wide>
				<Input
					type="tel"
					label={'Puhelinnumero'}
					placeholder="Esim. +358501234567"
					{...phone}
				/>
			</Field>
			<Field wide>
				<Input
					type="email"
					label={'Sähköposti'}
					placeholder="Esim. matti.meikalainen@example.com"
					{...email}
				/>
			</Field>
			<FormNav
				page={page}
				totalPages={totalPages}
				onNext={updateAuthorizer}
				onPrev={onPrev}
				loading={loading}
			/>
		</Wrapper>
	)
}

export default Authorizer
