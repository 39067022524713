import React from 'react'
import styled from 'styled-components'
import Color from 'color'

const StyledInput = styled.input`
	position: relative;
	color: #222;
	padding: 5px 10px;
	font-size: 1em;
	width: 100%;
	box-sizing: border-box;
	background: transparent;
	font-family: inherit;
	outline: none;
	border: none;
	border-bottom: 2px solid;
	border-color: ${props => props.theme.primaryColor};
`

const TextFieldWrapper = styled.div`
	position: relative;
	color: inherit;
	padding: 5px 0px;
	padding-bottom: 0;
	border-radius: 5px 5px 0 0;
	border: 1px solid;
	border-color: inherit;
	cursor: text;
	background: #fafafa;
`

const InputLabel = styled.div`
	padding: 0px 10px;
	font-size: 0.8em;
	color: inherit;
	font-family: inherit;
	transition: transform 0.2s ease;
	transform-origin: top left;
	transform: translate(0px, 10px) scale(1.2);
`

const InputPlaceholder = styled.div`
	position: absolute;
	padding: 0px 10px;
	font-size: 1em;
	font-family: inherit;
	color: #aaa;
	width: 100%;
	transition: opacity 0.2s ease;
	transform-origin: top left;
	transform: translate(0px, 5px) scale(1);
	opacity: 0;
`

const Error = styled.div`
	padding: 0px 10px;
	font-size: 0.8em;
	height: 25px;
	color: ${props => props.theme.errorColor};
`

const InputWrapper = styled.label`
	position: relative;
	color: ${props => props.theme.primaryColor};
	transition: all 0.2s ease;
	&:hover {
		color: ${props => Color(props.theme.primaryColor).darken(0.3).string()};
		${StyledInput} {
			border-color: ${props =>
				Color(props.theme.primaryColor).darken(0.3).string()};
		}
	}
	&.focused {
		color: ${props => Color(props.theme.primaryColor).darken(0.3).string()};
		${StyledInput} {
			border-color: ${props =>
				Color(props.theme.primaryColor).darken(0.3).string()};
		}
		${InputLabel} {
			transform: translate(0px, 0px) scale(1);
		}
		${InputPlaceholder} {
			opacity: 1;
		}
	}
	&.hasValue {
		${InputLabel} {
			transform: translate(0px, 0px) scale(1);
		}
		${InputPlaceholder} {
			opacity: 0;
		}
	}
	&.error,
	&.error:hover {
		color: ${props => Color(props.theme.errorColor).lighten(0.5).string()};
		${StyledInput} {
			border-color: ${props =>
				Color(props.theme.errorColor).lighten(0.5).string()};
		}
	}
`

const Input = ({
	name,
	label,
	type,
	error,
	placeholder,
	value,
	onChange,
	onFocus,
	onBlur,
	ref,
}) => {
	const [focused, setFocused] = React.useState(false)
	const [fieldValue, setFieldValue] = React.useState('')

	const handleChange = e => {
		if (typeof onChange === 'function') {
			onChange({
				target: {
					value: e.target.value,
					name: name,
				},
			})
			return
		}
		setFieldValue(e.target.value)
	}

	const handleFocus = e => {
		setFocused(true)
		if (typeof onFocus === 'function') {
			onFocus(e)
		}
	}
	const handleBlur = e => {
		setFocused(false)
		if (typeof onBlur === 'function') {
			onBlur(e)
		}
	}

	const val = value || fieldValue

	let classes = []

	if (focused) {
		classes.push('focused')
	}
	if (error) {
		classes.push('error')
	}

	if (val) {
		classes.push('hasValue')
	}

	return (
		<InputWrapper className={classes.join(' ')}>
			<TextFieldWrapper>
				<InputLabel>{label}</InputLabel>
				<InputPlaceholder>{placeholder}</InputPlaceholder>
				<StyledInput
					name={name}
					type={type}
					value={val}
					onChange={handleChange}
					onFocus={handleFocus}
					onBlur={handleBlur}
					ref={ref}
				/>
			</TextFieldWrapper>
			<Error>{error}</Error>
		</InputWrapper>
	)
}

export default Input
