import React from 'react'
import styled from 'styled-components/macro'
import 'resize-observer-polyfill'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

const Wrapper = styled.div`
	position: relative;
	transition: height 0.5s ease;
	overflow: visible;
	width: 100%;
	box-sizing: border-box;
`

const Ani = styled.div`
	transition: all 0.5s ease;
	position: absolute;
	height: auto;
	width: 100%;
	&.next.enter {
		opacity: 0;
		transform: translateX(100%);
	}
	&.next.enter-active {
		opacity: 1;
		transform: translateX(0);
	}
	&.next.exit {
		opacity: 1;
		transform: translateX(0);
	}
	&.next.exit-active {
		opacity: 0;
		transform: translateX(-100%);
	}

	&.prev.enter {
		opacity: 0;
		transform: translateX(-100%);
	}
	&.prev.enter-active {
		opacity: 1;
		transform: translateX(0);
	}
	&.prev.exit {
		opacity: 1;
		transform: translateX(0);
	}
	&.prev.exit-active {
		opacity: 0;
		transform: translateX(100%);
	}
`

const Transition = ({ page, children, direction, nodeRef }) => (
	<CSSTransition key={page} timeout={500} nodeRef={nodeRef}>
		<Ani ref={nodeRef}>{children}</Ani>
	</CSSTransition>
)

const PageTransition = ({ page, direction, children }) => {
	const comp = React.useRef(null)
	const [size, setSize] = React.useState({ width: 0, height: 0 })

	const updateSize = () => {
		if (comp.current) {
			setSize(comp.current.getBoundingClientRect().toJSON())
		}
	}

	React.useLayoutEffect(() => {
		updateSize()
		let inter = setInterval(updateSize, 100)
		return () => {
			clearInterval(inter)
		}
	}, [comp, children, page])

	return (
		<Wrapper style={{ height: size.height }}>
			<TransitionGroup
				childFactory={child =>
					React.cloneElement(child, {
						className:
							child.className +
							(direction === -1 ? ' prev' : ' next'),
					})
				}
			>
				<Transition direction={direction} page={page} nodeRef={comp}>
					{children}
				</Transition>
			</TransitionGroup>
		</Wrapper>
	)
}

export default PageTransition
