import React from 'react'
import styled from 'styled-components'

const TimelineWrapper = styled.div`
	display: flex;
	padding: 20px 0;
	box-sizing: border-box;
	z-index: 200;
`

const TimelineLine = styled.div`
	position: absolute;
	background: #eee;
	height: 3px;
	width: 100%;
	transition: all 0.2s ease;
	transform: scaleX(0);
	transform-origin: top left;

	&.visited {
		background: ${props => props.theme.primaryColor};
		color: #fff;
		transform: scaleX(1);
	}

	&.last {
		display: none;
	}
`

const TimelineLinePlaceholder = styled.div`
	position: absolute;
	background: #eee;
	height: 3px;
	width: 100%;
	cursor: pointer;
`

const TimelineTitle = styled.div`
	color: #222;
	position: absolute;
	text-align: center;
	top: 25px;
	font-size: 0.6em;
	line-height: 1.2em;
	min-width: 80px;
	width: auto;
	opacity: 0;
	transition: opacity 0.2s ease;
	background: #fff;
	padding: 2px 5px;
	border-radius: 5px;
`

const TimelineItem = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	flex-grow: 1;
	flex-shrink: 1;
	min-width: 25px;
	&.last {
		flex-grow: 0;
	}
`

const TimelineButton = styled.div`
	position: relative;
	color: #666;
	background: #eee;
	width: 24px;
	height: 24px;
	text-align: center;
	line-height: 25px;
	border-radius: 50%;
	cursor: pointer;
	transition: all 0.2s ease;
	display: flex;
	justify-content: center;
	font-size: 10pt;
	font-weight: bold;

	&.visited,
	&.current {
		background: ${props => props.theme.primaryColor};
		color: #fff;
	}

	&.current,
	&:hover {
		z-index: 100;
		transform: scale(1.3);
		${TimelineTitle} {
			opacity: 1;
			z-index: 200;
		}
	}

	&:hover {
		z-index: 200;
		${TimelineTitle} {
			z-index: 700;
		}
	}
`

const TimelineItemElement = React.memo(
	({ classes, onClick, totalPages, item, title }) => (
		<TimelineItem className={classes}>
			{item !== totalPages - 1 && (
				<>
					<TimelineLinePlaceholder />
					<TimelineLine key={item + '-line'} className={classes} />
				</>
			)}
			<TimelineButton
				key={item + '-button'}
				onClick={onClick}
				className={classes}
			>
				{item + 1}
				<TimelineTitle>{title}</TimelineTitle>
			</TimelineButton>
		</TimelineItem>
	),
)

const RenderPages = ({ totalPages, page, pages, moveTo }) => {
	let comps = []

	for (let i = 0; i < totalPages; i++) {
		let classes = []

		if (i < page) {
			classes = [...classes, 'visited']
		}
		if (i === page) {
			classes = [...classes, 'current']
		}
		if (i === totalPages - 1) {
			classes = [...classes, 'last']
		}

		let component = (
			<TimelineItemElement
				key={i}
				classes={classes.join(' ')}
				item={i}
				totalPages={totalPages}
				onClick={() => moveTo(i)}
				title={pages[i].title}
			/>
		)
		comps.push(component)
	}

	return comps
}

const Timeline = ({ page, totalPages, moveTo, pages }) => {
	return (
		<TimelineWrapper>
			<RenderPages
				page={page}
				totalPages={totalPages}
				moveTo={moveTo}
				pages={pages}
			/>
		</TimelineWrapper>
	)
}

export default Timeline
